import React, { useRef } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { Carousel } from 'antd'
import { Box } from '@qonsoll/react-design'
import { LeftOutlined, RightOutlined } from '@ant-design/icons'

const ArrowWrapper = styled(Box)`
  color: var(--dark-2);
  position: absolute;
  height: 100%;
  display: flex;
  align-items: center;
  z-index: 10;
  cursor: pointer;
  font-size: var(--ql-font-size-h1);
  transition: var(--transition-default);

  :hover {
    color: var(--dark-5);
  }
`

function CustomCarousel(props) {
  const { children, noOuterGutters, data, ...rest } = props

  const carouselRef = useRef()

  return (
    <>
      {data?.length > 1 && (
        <ArrowWrapper
          onClick={() => {
            carouselRef.current.prev()
          }}>
          <LeftOutlined />
        </ArrowWrapper>
      )}

      <Carousel ref={carouselRef} {...rest}>
        {children}
      </Carousel>

      {data?.length > 1 && (
        <ArrowWrapper
          right={!noOuterGutters ? '16px' : 0}
          onClick={() => {
            carouselRef.current.next()
          }}>
          <RightOutlined />
        </ArrowWrapper>
      )}
    </>
  )
}

CustomCarousel.propTypes = {
  data: PropTypes.arrayOf(PropTypes.any).isRequired,
  noOuterGutters: PropTypes.bool
}

export default CustomCarousel
