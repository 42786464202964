const ROUTE_PATHS = {
  404: '/404',
  ACCESS_DENIED: '/403',
  LANGUAGES_ALL: '/languages',
  SETTINGS: '/settings',
  OFFER_CREATE: '/offer/new',
  OFFER_SHOW: '/offers/:id',
  OFFER_EDIT: '/offers/:id/edit',
  OFFERS_ALL: '/offers',
  PARTNERS_ALL: '/partners',
  PARTNER_EDIT: '/partners/:id/edit',
  PARTNER_SHOW: '/partners/:id',
  RECENT_ACTIVITIES_ALL: '/recent-activities',
  LOGIN: '/login',
  FORGOT_PASSWORD: '/forgot-password',
  RESET_PASSWORD: '/reset-password',
  USER_SHOW: '/users/:id',
  USER_EDIT: '/users/:id/edit',
  USER_CREATE: '/users/new',
  GDPR_PAGE: '/cookie-policy'
}

export default ROUTE_PATHS
