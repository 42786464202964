import { useReducer, useEffect } from 'react'
import PropTypes from 'prop-types'
import { message } from 'antd'
import { AppWrapper } from '@qonsoll/react-design'
import {
  AppHeader,
  BottomNavigation,
  PageLoading,
  SideNavigation
} from 'app/components'
import { useSessionContext } from '~/app/contexts/Session/hooks'
import { useTranslations } from 'app/contexts/Translation/hooks'
import UIContext from '../../context'
import UIReducer from '../../UIReducer'
import { useResponsiveBreakpoints } from '../../hooks'

const DEFAULT_UI_STATE = {
  isDeviceIphone: false,
  onBack: false,
  isSidebarCollapsed: false
}

const NAVIGATION_BREAKPOINTS = {
  sm: false,
  md: true,
  lg: true,
  xl: true,
  xxl: true
}

const UIProvider = (props) => {
  const { UI = DEFAULT_UI_STATE, children, ...rest } = props

  // [ADDITIONAL HOOKS]
  const session = useSessionContext()
  const { translateLoading, languageSwitch } = useTranslations()
  const responsive = useResponsiveBreakpoints(NAVIGATION_BREAKPOINTS)

  // [COMPONENT_STATE_HOOKS]
  const [state, dispatch] = useReducer(UIReducer, UI)

  // [COMPUTED_PROPERTIES]
  const isSessionExist = session.state
  const initLoading = isSessionExist && (!translateLoading || languageSwitch)

  // [USE_EFFECTS]
  useEffect(() => {
    function handleEvent(data) {
      const safeAreaInsets = data.data.safeAreaInsets
      dispatch({ type: 'SET_STATE', payload: { safeAreaInsets } })
      // Config antd message component top indent in web-view app.
      message.config({ top: safeAreaInsets.top })
    }
    document.addEventListener('message', handleEvent)
    return () => document.removeEventListener('message', handleEvent)
  }, [])

  useEffect(() => {
    let theme = ''
    if (session?.state?.themeMode) {
      theme = session?.state?.themeMode
      localStorage.setItem('theme', theme || 'light')
    } else {
      theme = localStorage.getItem('theme') || 'light'
    }
    document.documentElement.className = theme
  }, [session?.state?.themeMode])

  return (
    <UIContext.Provider value={{ state, dispatch }} {...rest}>
      <AppWrapper
        backgroundImage="var(--layout-body-background)"
        appHeader={<AppHeader session={initLoading && isSessionExist} />}
        bottomNavbar={
          initLoading &&
          !responsive && <BottomNavigation isIphone={state.isDeviceIphone} />
        }
        horizontal={initLoading && responsive}
        appNavbar={
          isSessionExist &&
          responsive && <SideNavigation session={isSessionExist} />
        }
        contentProps={{
          py: 24
        }}>
        <PageLoading
          loadingText={isSessionExist && translateLoading && 'Translating'}
          loading={
            session.sessionLoading || (translateLoading && !languageSwitch)
          }>
          {children}
        </PageLoading>
      </AppWrapper>
    </UIContext.Provider>
  )
}

UIProvider.propTypes = {
  children: PropTypes.element.isRequired,
  UI: PropTypes.object
}

export default UIProvider
