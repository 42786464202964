import React, { useState, useEffect } from 'react'
import { generatePath, withRouter } from 'react-router-dom'
import { Menu, Grid } from 'antd'
import { Box, Text, Avatar, Img, SiderOld } from '@qonsoll/react-design'
import {
  BuildOutlined,
  SettingOutlined,
  UserOutlined,
  LeftOutlined
} from '@ant-design/icons'
import { useTranslations } from 'app/contexts/Translation/hooks'
import { useUI } from 'app/contexts/UI/hooks'
import ROUTE_PATHS from 'domains/allRoutePath'

const { useBreakpoint } = Grid

const MENU = [
  { name: 'offers', icon: BuildOutlined, key: ROUTE_PATHS.OFFERS_ALL },
  {
    name: 'settings',
    icon: SettingOutlined,
    key: ROUTE_PATHS.SETTINGS,
    nested: [ROUTE_PATHS.PARTNERS_ALL, ROUTE_PATHS.GDPR_PAGE]
  }
]

const THEME_LOGO = {
  dark: 'light',
  light: 'dark'
}

function SideNavigation(props) {
  const { session, history } = props

  // [ADDITIONAL_HOOKS]
  const UI = useUI()
  const { t } = useTranslations()
  const screen = useBreakpoint()

  // [COMPONENT_STATE_HOOKS]
  const [currentPage, setCurrentPage] = useState('')
  const [collapsed, setCollapsed] = useState(false)

  // [COMPUTED_PROPERTIES]
  const profilePath = generatePath(ROUTE_PATHS.USER_SHOW, { id: session.id })

  // [CLEAN_FUNCTIONS]
  const onItemClick = (key) => {
    setCurrentPage(key)
    history.push(key)
  }

  const toggle = (value) => {
    setCollapsed(value)
    UI.dispatch({
      type: 'TOGGLE_SIDEBAR_COLLAPSE',
      payload: value
    })
  }

  const goToHome = () => {
    history.push(ROUTE_PATHS.OFFERS_ALL)
  }

  // [USE_EFFECTS]
  useEffect(() => {
    setCurrentPage(history.location.pathname)

    MENU.forEach((menuItem) => {
      if (
        new RegExp(menuItem?.nested?.join('|') || menuItem.key).test(
          history.location.pathname
        )
      ) {
        setCurrentPage(menuItem.key)
      }
    })
    if (
      new RegExp([profilePath, ROUTE_PATHS.RESET_PASSWORD].join('|')).test(
        history.location.pathname
      )
    ) {
      setCurrentPage(profilePath)
    }
  }, [history.location.pathname, profilePath])

  // Need to automatically collapse menu on screens <'lg' breakpoint
  useEffect(
    () => toggle(!screen.lg), // eslint-disable-next-line react-hooks/exhaustive-deps
    [screen]
  )
  return (
    <SiderOld
      height="inherit"
      width={250}
      trigger={null}
      collapsible
      collapsed={collapsed}
      py={24}
      style={{ backgroundColor: 'transparent' }}>
      <Box display="flex" flex={1} justifyContent="center" my={2}>
        <Img
          height="32px"
          src={`/logo/logo-symbol-${
            THEME_LOGO[session?.themeMode || 'light']
          }.svg`}
          alt={t('Buy together logo')}
          onClick={goToHome}
          cursor="pointer"
        />
      </Box>
      <Menu
        mode="inline"
        className="ant-menu-inline"
        selectedKeys={[currentPage]}
        style={{
          borderRight: 0
        }}>
        <Menu.Item
          onClick={() => toggle(!collapsed)}
          icon={
            <LeftOutlined
              rotate={collapsed && 180}
              style={{
                fontSize: 'var(--ql-font-size-h3)',
                lineHeight: 1
              }}
            />
          }
          style={{ paddingLeft: '24px' }}>
          <Text
            variant="h5"
            color="var(--menu-item-color)"
            textTransform="uppercase">
            {t('Collapse')}
          </Text>
        </Menu.Item>
        {MENU.map((item) => (
          <Menu.Item
            onClick={() => onItemClick(item.key)}
            key={item.key}
            icon={
              <item.icon
                style={{
                  fontSize: 'var(--ql-font-size-h3)',
                  color: currentPage === item.key && 'var(--ql-color-accent1)',
                  lineHeight: 1
                }}
              />
            }
            style={{ paddingLeft: '24px' }}>
            <Text
              variant="h5"
              color={
                currentPage === item.key
                  ? 'var(--menu-horizontal-main-item-color-hover)'
                  : 'var(--menu-item-color)'
              }
              textTransform="uppercase">
              {t(item.name)}
            </Text>
          </Menu.Item>
        ))}
        <Menu.Item
          onClick={() => onItemClick(profilePath)}
          key={profilePath}
          style={{
            position: 'absolute',
            bottom: '16px',
            paddingLeft: '24px',
            width: '100%'
          }}
          icon={
            <Avatar
              width={30}
              height={30}
              fontSize="16px !important"
              style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center'
              }}
              icon={<UserOutlined />}
              src={session?.avatarURL}
            />
          }>
          <Text
            variant="h5"
            color={
              currentPage === profilePath
                ? 'var(--menu-horizontal-main-item-color-hover)'
                : 'var(--menu-item-color)'
            }>
            {session.firstName} {session.secondName}
          </Text>
        </Menu.Item>
      </Menu>
    </SiderOld>
  )
}

export default withRouter(SideNavigation)
