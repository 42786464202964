import React from 'react'
import PropTypes from 'prop-types'
import { Row, Col } from '@qonsoll/react-design'
import OfferListItem from 'domains/Offer/components/OfferListItem'
import { Empty } from 'antd'

function OffersList(props) {
  const { data } = props

  return (
    <>
      {data?.length ? (
        <Row negativeBlockMargin>
          {data?.map((item, index) => (
            <Col cw={[12, 5, 4, 3]}>
              <OfferListItem {...item} key={index} />
            </Col>
          ))}
        </Row>
      ) : (
        <Row height="100%">
          <Col v="center">
            <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
          </Col>
        </Row>
      )}
    </>
  )
}

OffersList.propTypes = { data: PropTypes.array }

export default OffersList
