import { useState } from 'react'
import PropTypes from 'prop-types'
import _ from 'lodash'
import { Form, Select, InputNumber } from 'antd'
import {
  Button,
  TextArea,
  Input,
  PhoneInput,
  Box,
  Col,
  Row
} from '@qonsoll/react-design'
import { ImagesUploader, UploadButton } from '~/app/components'
import { useTranslations } from '~/app/contexts/Translation/hooks'

const CURRENCIES = ['NOK']

function OfferForm(props) {
  const { onRemoveForResized, offersTypes, onFinish, form, edit, initialType } =
    props

  // [ADDITIONAL HOOKS]
  const { t } = useTranslations()

  //[STATE_HOOKS]
  const [visible, setVisible] = useState(!!edit)
  const [type, setType] = useState(initialType || {})

  // [CLEAN FUNCTIONS]
  const onChangeSelectType = (_, object) => {
    setVisible(true)
    setType(object.key)
  }

  return (
    <Form
      onFinish={(data) => onFinish(data, type)}
      form={form}
      style={{
        display: 'flex',
        flexDirection: 'column'
      }}
      layout="vertical"
      requiredMark={false}>
      <Box>
        <Form.Item
          label={t('Offer type')}
          name="type"
          style={{ width: '100%' }}
          rules={[
            {
              required: true,
              message: t('Choose type, please')
            }
          ]}>
          <Select
            onChange={onChangeSelectType}
            placeholder={t('Type')}
            options={offersTypes?.map((type) => ({
              value: type?.name,
              key: type,
              label: _.capitalize(t(type?.name))
            }))}
          />
        </Form.Item>
      </Box>
      {visible && (
        <>
          <Box display="flex" justifyContent="center">
            <Form.Item
              name="images"
              rules={[{ required: true, message: t('Please, upload images') }]}
              style={{ width: '100%' }}>
              <ImagesUploader onRemoveForResized={onRemoveForResized}>
                <UploadButton />
              </ImagesUploader>
            </Form.Item>
          </Box>
          <Form.Item
            label={t('Title')}
            name="title"
            rules={[
              { required: true, message: t('Enter offer title, please') },
              {
                max: 150,
                message: 'Name should be less than 150 character'
              }
            ]}>
            <Input placeholder={t('Title')} />
          </Form.Item>
          <Form.Item
            label={t('Description')}
            name="description"
            rules={[
              { required: true, message: t('Enter offer description, please') },
              {
                max: 2500,
                message: 'Description should be less than 2500 character'
              }
            ]}>
            <TextArea placeholder={t('Description')} rows={4} />
          </Form.Item>
          <Form.Item
            label={t('Location')}
            name="location"
            rules={[
              { required: true, message: t('Please enter location') },
              {
                max: 100,
                message: 'Location should be less than 100 character'
              }
            ]}>
            <Input placeholder={t('Enter location')} />
          </Form.Item>
          <Row negativeBlockMargin>
            <Col cw={[12, 12, 6]} display="block">
              <Form.Item
                label={t('Contact person email')}
                name="email"
                rules={[
                  { required: true, message: t('Please, enter contact email') },
                  { type: 'email', message: t('Please, enter valid email') }
                ]}
                style={{ width: '100%' }}>
                <Input placeholder={t('Enter contact person email')} />
              </Form.Item>
            </Col>
            <Col cw={[12, 12, 6]} display="block">
              <Form.Item
                label={t('Contact person phone')}
                name="phone"
                rules={[
                  { required: true, message: t('Please, enter contact phone') }
                ]}
                style={{ width: '100%' }}>
                <PhoneInput country="no" />
              </Form.Item>
            </Col>
          </Row>
          <Row negativeBlockMargin>
            {type.fixedDiscountPercentage && (
              <Col cw={12} display="block">
                <Form.Item
                  label={t('Fixed discount percentage')}
                  name="fixedDiscountPercentage"
                  rules={[
                    {
                      required: true,
                      message: t(
                        'Enter offer fixed discount percentage, please'
                      )
                    }
                  ]}
                  style={{ width: '100%' }}>
                  <InputNumber
                    min={0}
                    max={100}
                    placeholder={t('Fixed discount percentage')}
                    style={{ width: '100%' }}
                    formatter={(value) => `${value}%`}
                    parser={(value) => value.replace('%', '')}
                  />
                </Form.Item>
              </Col>
            )}
            {type.fixedPrice && (
              <Col cw={6} display="block">
                <Form.Item
                  label={t('Fixed price')}
                  name="fixedPrice"
                  rules={[
                    {
                      required: true,
                      message: t('Enter fixed price, please')
                    }
                  ]}
                  style={{ width: '100%' }}>
                  <InputNumber
                    min={0}
                    placeholder={t('Fixed price')}
                    style={{ width: '100%' }}
                  />
                </Form.Item>
              </Col>
            )}

            {type.fixedDiscount && (
              <Col cw={6} display="block">
                <Form.Item
                  label={t('Fixed discount')}
                  name="fixedDiscount"
                  rules={[
                    {
                      required: true,
                      message: t('Enter offer fixed discount, please')
                    }
                  ]}
                  style={{ width: '100%' }}>
                  <InputNumber
                    min={0}
                    placeholder={t('Fixed discount')}
                    style={{ width: '100%' }}
                  />
                </Form.Item>
              </Col>
            )}
            {type.price && (
              <Col cw={6} display="block">
                <Form.Item
                  label={t('Price')}
                  name="price"
                  rules={[
                    {
                      required: true,
                      message: t('Enter price, please')
                    }
                  ]}
                  style={{ width: '100%' }}>
                  <InputNumber
                    min={0}
                    placeholder={t('Price')}
                    style={{ width: '100%' }}
                  />
                </Form.Item>
              </Col>
            )}
            {type.fixedPercentage && (
              <Col cw={6} display="block">
                <Form.Item
                  label={t('Fixed percentage')}
                  name="fixedPercentage"
                  rules={[
                    {
                      required: true,
                      message: t('Enter offer fixed  percentage, please')
                    }
                  ]}
                  style={{ width: '100%' }}>
                  <InputNumber
                    min={0}
                    max={100}
                    placeholder={t('Fixed percentage')}
                    style={{ width: '100%' }}
                    formatter={(value) => `${value}%`}
                    parser={(value) => value.replace('%', '')}
                  />
                </Form.Item>
              </Col>
            )}
            {type.currency && (
              <Col cw={6} display="block">
                <Form.Item
                  label={t('Currency')}
                  name="currency"
                  style={{ width: '100%' }}
                  initialValue={CURRENCIES[0]}
                  rules={[
                    {
                      required: true,
                      message: t('Choose currency, please')
                    }
                  ]}>
                  <Select
                    placeholder={t('Currency')}
                    options={CURRENCIES.map((currency) => ({
                      value: currency,
                      label: t(currency)
                    }))}
                  />
                </Form.Item>
              </Col>
            )}
          </Row>
          <Button htmlType="submit" block type="primary" mt="auto" mb={3}>
            {edit ? t('Save changes') : t('Create offer')}
          </Button>
        </>
      )}
    </Form>
  )
}
OfferForm.propTypes = {
  onFinish: PropTypes.func.isRequired,
  form: PropTypes.object,
  edit: PropTypes.bool,
  offersTypes: PropTypes.array.isRequired,
  initialType: PropTypes.object,
  onRemoveForResized: PropTypes.array
}
export default OfferForm
