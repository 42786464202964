import { OffersList } from 'domains/Offer/components'
import React from 'react'
import {
  Row,
  Col,
  Container,
  Button,
  PageWrapper,
  Text
} from '@qonsoll/react-design'
import { useBackButton, useResponsiveBreakpoints } from 'app/contexts/UI/hooks'
import { generatePath, useHistory, useParams } from 'react-router-dom'
import {
  useCollectionData,
  useDocumentData
} from 'react-firebase-hooks/firestore'
import { deleteDocument, getCollectionRef } from 'services/Firebase/firestore'
import { COLLECTIONS } from 'app/constants'
import { PartnerAdvancedView } from 'domains/Partner/components'
import { PageLoading, Remove } from '~/app/components'
import { message } from 'antd'
import ROUTE_PATHS from 'domains/allRoutePath'
import { useTranslations } from 'app/contexts/Translation/hooks'
import { EditOutlined } from '@ant-design/icons'

function PartnerProfile() {
  // [ADDITIONAL_HOOKS]
  const { id } = useParams()
  const history = useHistory()
  const { t } = useTranslations()
  const backButtonVisibility = useResponsiveBreakpoints({
    sm: false,
    md: true,
    lg: true,
    xl: true,
    xxl: true
  })
  const goBack = useBackButton({ returnCallback: backButtonVisibility })
  const [partnerData, partnerLoading] = useDocumentData(
    getCollectionRef(COLLECTIONS.PARTNERS).doc(id)
  )

  const [contactsData, contactsLoading] = useCollectionData(
    getCollectionRef(COLLECTIONS.USERS).where('partnerId', '==', id)
  )
  const [offers, offersLoading] = useCollectionData(
    getCollectionRef(COLLECTIONS.OFFERS).where('partnerId', '==', id)
  )
  // [CLEAN_FUNCTIONS]
  const onRemove = async () => {
    try {
      await deleteDocument(COLLECTIONS.PARTNERS, id)
      if (offers?.length > 0) {
        for (const offer of offers) {
          await deleteDocument(COLLECTIONS.OFFERS, offer?.id)
        }
      }
      //TODO insert functions for delete contactsData(partner)
      message.success(t('Partner was delete'))
      history.push(ROUTE_PATHS.PARTNERS_ALL)
    } catch (err) {
      console.log(err)
      message.error(t('Partner was not deleted'))
    }
  }

  const onEditClick = () => {
    const toEditPartner = generatePath(ROUTE_PATHS.PARTNER_EDIT, { id })
    history.replace(toEditPartner, { partnerID: id })
  }

  return (
    <PageLoading loading={partnerLoading || contactsLoading || offersLoading}>
      <PageWrapper onBack={goBack} divided={false} isBottomSticky>
        <Container
          height="inherit"
          display="flex"
          style={{
            flexDirection: 'column',
            justifyContent: 'space-between'
          }}>
          <PartnerAdvancedView
            {...partnerData}
            contactsData={contactsData}
            onRemove={onRemove}
          />
          {offers?.length > 0 && (
            <Row noGutters>
              <Col cw={12}>
                <Text variant="h5" mb={3}>
                  {t('Offers')}
                </Text>
              </Col>
              <Col cw={12}>
                <OffersList data={offers} />
              </Col>
            </Row>
          )}
          <Row noGutters mt="auto">
            <Col cw={12} mb={2}>
              <Button icon={<EditOutlined />} block onClick={onEditClick}>
                {t('Edit partner')}
              </Button>
            </Col>
            <Col cw={12} mb={3}>
              <Remove
                text={t('Delete partner')}
                type="primary"
                block
                onSubmit={onRemove}
              />
            </Col>
          </Row>
        </Container>
      </PageWrapper>
    </PageLoading>
  )
}

export default PartnerProfile
