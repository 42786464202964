import React, { useState, useEffect, useCallback } from 'react'
import { generatePath, useHistory } from 'react-router-dom'
import { Form, message } from 'antd'
import { Col, Container, Row, PageWrapper } from '@qonsoll/react-design'
import {
  getCollectionRef,
  getDocumentData,
  updateDocument
} from '~/services/Firebase/firestore'
import ROUTE_PATHS from 'domains/allRoutePath'
import { COLLECTIONS } from '~/app/constants'
import {
  useBackButton,
  useResponsiveBreakpoints
} from '~/app/contexts/UI/hooks'
import { PageLoading } from '~/app/components'
import { useCollectionData } from '~/hooks/firestore'
import { PartnerEditForm } from 'domains/Partner/components'
import { createContactPerson } from 'domains/Partner/helpers'
import { useTranslations } from 'app/contexts/Translation/hooks'
import { deleteURL } from '~/services/Firebase/storage'

function PartnerEdit() {
  // [ADDITIONAL_HOOKS]
  const backButtonVisibility = useResponsiveBreakpoints({
    sm: false,
    md: true,
    lg: true,
    xl: true,
    xxl: true
  })
  const { t } = useTranslations()
  const history = useHistory()
  const [form] = Form.useForm()
  const partnerID = history.location.state?.partnerID
  const titleSizes = useResponsiveBreakpoints({
    sm: 4,
    md: 3,
    lg: 3,
    xl: 3,
    xxl: 3
  })

  // [COMPONENT STATE HOOKS]
  const [loadingForUpdateData, setLoadingForUpdateData] = useState(false)
  const [loading, setLoading] = useState(true)
  const [offerType, setOfferType] = useState({})
  const [partner, setPartner] = useState({})
  const [diffImage, setDiffImage] = useState([''])

  const [contactPersons, loadingPersons] = useCollectionData(
    getCollectionRef(COLLECTIONS.USERS).where('partnerId', '==', partnerID)
  )
  const [offersTypes, loadingOffersType] = useCollectionData(
    getCollectionRef(COLLECTIONS.OFFER_TYPES)
  )

  // [CLEAN_FUNCTIONS]
  const onPartnerEdit = async (values) => {
    const oldEmails = contactPersons.map((oldEmail) => oldEmail.email)
    const emailsForSendInvite = values.contactPeople?.filter((item) => {
      return oldEmails?.indexOf(item.email) < 0
    })

    if (partner?.logoURL !== values?.logoURL) {
      //delete prev image with best pixels
      await deleteURL(partner?.logoURL).catch((error) => {
        console.log(error.message)
      })
    }
    setLoadingForUpdateData(true)
    try {
      delete values?.contactPeople

      //pop the last url for not remove from storage last upload image
      setDiffImage(...diffImage?.pop())
      await updateDocument(COLLECTIONS.PARTNERS, partnerID, values)
      if (emailsForSendInvite?.length > 0) {
        for (let item of emailsForSendInvite) {
          await createContactPerson(item, partnerID, t)
        }
      }
      message.success(t('Successfully edit company '))
      history.push(generatePath(ROUTE_PATHS.PARTNER_SHOW, { id: partnerID }))
    } catch (err) {
      console.log(err.message)
      message.error(t('Error occurred during partner creation'))
    }
    setLoadingForUpdateData(false)
  }
  // [USE_EFFECTS]
  useEffect(() => {
    let isComponentMount = true

    const fetchData = async () => {
      // Fetch request
      const partnerData = await getDocumentData(COLLECTIONS.PARTNERS, partnerID)
      const typeOffer = await getDocumentData(
        COLLECTIONS.OFFER_TYPES,
        partnerData?.offerTypeId
      )
      setOfferType(typeOffer)
      setPartner(partnerData)
      form.setFieldsValue({
        ...partnerData
      })
      setLoading(false)
    }

    isComponentMount && fetchData()

    return () => {
      isComponentMount = false
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const checkIfUserSaveNewAvatar = useCallback(async () => {
    if (Array.isArray(diffImage)) {
      for (const image of diffImage) {
        if (image !== partner?.logoURL) {
          await deleteURL(image).catch((error) => {
            console.log(error.message)
          })
        }
      }
    } // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [diffImage])

  const goBack = useBackButton({
    returnCallback: backButtonVisibility,
    callback: checkIfUserSaveNewAvatar
  })

  // [USE_EFFECTS]
  useEffect(() => {
    return () => {
      checkIfUserSaveNewAvatar()
    } // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [diffImage])
  return (
    <PageLoading loading={loading || loadingPersons || loadingOffersType}>
      <PageWrapper
        headingProps={{
          title: t('Edit partner'),
          titleSize: titleSizes,
          marginBottom: '24px'
        }}
        onBack={goBack}
        isBottomSticky>
        <Container height="inherit" display="grid">
          <Row noGutters h="center">
            <Col cw={[12, 10, 8, 7, 6]} display="grid">
              <PartnerEditForm
                form={form}
                diffImage={diffImage}
                setDiffImage={setDiffImage}
                onFinish={onPartnerEdit}
                loading={loadingForUpdateData}
                setFormLoading={setLoadingForUpdateData}
                initialType={offerType}
                offersTypes={offersTypes}
                logoURL={partner?.logoURL}
              />
            </Col>
          </Row>
        </Container>
      </PageWrapper>
    </PageLoading>
  )
}

export default PartnerEdit
