import generator from 'generate-password'
import _ from 'lodash'
import firebase from '~/services/Firebase/init'
import { createDocument } from '~/services/Firebase/firestore'
import { COLLECTIONS } from '~/app/constants'
import { message } from 'antd'

const createContactPerson = async (personData, partnerId, t) => {
  const password = generator.generate({
    length: 10,
    numbers: true
  })
  const func = firebase.functions().httpsCallable('sendInvite', { timeout: 0 })
  const { data } = await func({
    email: personData.email,
    password: password,
    firstName: personData.firstName,
    lastName: personData.secondName,
    themeMode: 'light',
    phone: `+${personData.phone}`,
    link: process.env.REACT_APP_HOSTNAME
  })
  const responseData = data
  const userId = responseData.data
  if (userId) {
    await createDocument(
      COLLECTIONS.USERS,
      {
        id: userId,
        firstName: personData.firstName,
        secondName: personData.secondName,
        phone: `+${personData.phone}`,
        email: personData.email,
        role: 'PARTNER',
        partnerId: partnerId,
        isActivated: false
      },
      { docId: userId }
    )
  } else {
    const error = responseData.error
    console.log(error)
    message.error(
      `${t(_.trimEnd(error.message, '.'))} occurred during invitation of ${
        personData.firstName
      } ${personData.secondName} ${t(`user`)}`
    )
  }
}
export default createContactPerson
