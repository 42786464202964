import React from 'react'
import PropTypes from 'prop-types'
import { Form, Input as AntdInput } from 'antd'
import { Button, Input } from '@qonsoll/react-design'
import { useTranslations } from 'app/contexts/Translation/hooks'

function ResetPasswordForm(props) {
  //[ADDITIONAL_HOOKS]
  const { t } = useTranslations()

  return (
    <Form
      style={{
        display: 'flex',
        flexDirection: 'column'
      }}
      {...props}>
      <Form.Item name="oldPassword">
        <Input
          autoFocus
          autoComplete="off"
          placeholder={t('Enter old password')}
        />
      </Form.Item>
      <Form.Item
        name="newPassword"
        hasFeedback
        dependencies={['oldPassword']}
        rules={[
          { required: true, message: t('Incorrect password') },
          { min: 6, message: t('At least 6 symbols') },
          ({ getFieldValue }) => ({
            validator(_, value) {
              if (!value || getFieldValue('oldPassword') !== value) {
                return Promise.resolve()
              }
              return Promise.reject(
                new Error(
                  t('The old password should not look like the new one!')
                )
              )
            }
          })
        ]}>
        <AntdInput.Password placeholder={t('Password')} />
      </Form.Item>
      <Form.Item
        name="confirm"
        dependencies={['newPassword']}
        hasFeedback
        rules={[
          {
            required: true,
            message: t('Please confirm your password!')
          },
          ({ getFieldValue }) => ({
            validator(_, value) {
              if (!value || getFieldValue('newPassword') === value) {
                return Promise.resolve()
              }
              return Promise.reject(
                new Error(t('The two passwords that you entered do not match!'))
              )
            }
          })
        ]}>
        <AntdInput.Password placeholder={t('Confirm password')} />
      </Form.Item>
      <Button type="primary" block htmlType="submit" mt="auto">
        {t('Change my password')}
      </Button>
    </Form>
  )
}

ResetPasswordForm.propTypes = {
  onFinish: PropTypes.func.isRequired
}

export default ResetPasswordForm
