import React, { cloneElement } from 'react'
import PropTypes from 'prop-types'
import { Row, Col, Text, Card, Img } from '@qonsoll/react-design'
import { Carousel } from 'antd'
import { useHistory, generatePath } from 'react-router-dom'
import ROUTE_PATHS from 'domains/allRoutePath'
import { useResponsiveBreakpoints } from 'app/contexts/UI/hooks'

const config = { sm: 220, md: 120, lg: 120, xl: 150, xxl: 210 }

function OfferListItem(props) {
  const { id, category, title, images_500, images_1000 } = props

  const images = useResponsiveBreakpoints({
    sm: images_500,
    md: images_500,
    lg: images_1000,
    xl: images_1000,
    xxl: images_1000
  })

  // [ADDITIONAL_HOOKS]
  const history = useHistory()
  const responsive = useResponsiveBreakpoints(config)
  // [CLEAN_FUNCTIONS]
  const onItemClick = (id) =>
    history.push(generatePath(ROUTE_PATHS.OFFER_SHOW, { id }))

  return (
    <Card
      onClick={() => onItemClick(id)}
      style={{
        cursor: 'pointer',
        width: images?.length < 2 && '-webkit-fill-available'
      }}
      mb={3}
      bordered={false}
      shadowless
      size="small"
      overflow="hidden"
      cover={
        <Row noGutters>
          <Col minWidth={0}>
            <Carousel
              appendDots={(dots) => (
                <ul>
                  {dots.map((item) =>
                    cloneElement(item, {
                      onClick: (e) => e.stopPropagation()
                    })
                  )}
                </ul>
              )}>
              {images?.map((item) => (
                <Img
                  key={item}
                  src={item}
                  alt={item}
                  height={responsive}
                  objectFit="cover"
                />
              ))}
            </Carousel>
          </Col>
        </Row>
      }>
      <Row noGutters>
        <Col minWidth={0}>
          <Row noGutters minWidth={0}>
            <Col cw={12}>
              <Text
                variant="h5"
                fontWeight={'var(--ql-font-weight-medium)'}
                isEllipsis>
                {title}
              </Text>
            </Col>
            <Col cw={12}>
              <Text>{category}</Text>
            </Col>
          </Row>
        </Col>
      </Row>
    </Card>
  )
}

OfferListItem.propTypes = { data: PropTypes.object.isRequired }

export default OfferListItem
