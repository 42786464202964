import React from 'react'
import PropTypes from 'prop-types'
import { Form, Button } from 'antd'
import { Row, Col, Box, Link, Input } from '@qonsoll/react-design'
import { useTranslations } from 'app/contexts/Translation/hooks'

function LoginForm(props) {
  const { onForgotPassword, ...rest } = props

  //[ADDITIONAL HOOKS]
  const { t } = useTranslations()

  return (
    <Form {...rest}>
      <Form.Item
        name="email"
        rules={[
          {
            type: 'email',
            required: true,
            message: t('Please enter valid email')
          }
        ]}>
        <Input autoFocus placeholder={t('Email')} />
      </Form.Item>
      <Form.Item
        name="password"
        rules={[{ required: true, message: t('Incorrect password') }]}>
        <Input.Password placeholder={t('Password')} />
      </Form.Item>
      <Row noOuterGutters h="evenly" v="center">
        <Col>
          <Box textAlign="center">
            <Link onClick={onForgotPassword}>{t('Forgot password?')}</Link>
          </Box>
        </Col>
        <Col>
          <Button block htmlType="submit" type="primary">
            {t('Login')}
          </Button>
        </Col>
      </Row>
    </Form>
  )
}

LoginForm.propTypes = {
  onFinish: PropTypes.func.isRequired,
  onForgotPassword: PropTypes.func.isRequired
}

export default LoginForm
