import React from 'react'
import PropTypes from 'prop-types'
import { Form } from 'antd'
import { Input, Button, PhoneInput, Box } from '@qonsoll/react-design'
import { AvatarUploader } from 'app/components'
import { useTranslations } from 'app/contexts/Translation/hooks'

function UserForm(props) {
  const {
    initialValues,
    onFinish,
    setFormLoading,
    loading,
    diffImage,
    setDiffImage,
    ...rest
  } = props

  //[ADDITIONAL_HOOKS]
  const { t } = useTranslations()

  const onValuesChange = (data) => {
    if ('avatarURL' in data) {
      setDiffImage([...diffImage, data?.avatarURL])
    }
  }
  return (
    <Form
      onValuesChange={onValuesChange}
      onFinish={onFinish}
      initialValues={initialValues}
      layout="vertical"
      requiredMark={false}
      style={{
        display: 'flex',
        flexDirection: 'column',
        minHeight: '-webkit-fill-available'
      }}>
      <Form.Item name="avatarURL" style={{ marginBottom: -24 }}>
        <AvatarUploader
          imageUrl={initialValues?.avatarURL}
          size="large"
          setLoading={setFormLoading}
          {...rest}
        />
      </Form.Item>
      <Box>
        <Form.Item
          label={t('First name')}
          name="firstName"
          rules={[
            { required: true, message: t('Please input your first name') },
            {
              max: 75,
              message: 'First name should be less than 75 character'
            }
          ]}>
          <Input autoFocus placeholder={t('First name')} />
        </Form.Item>
        <Form.Item
          label={t('Last name')}
          name="secondName"
          rules={[
            { required: true, message: t('Please input your last name') },
            {
              max: 75,
              message: 'Second name should be less than 75 character'
            }
          ]}>
          <Input placeholder={t('Last name')} />
        </Form.Item>

        <Form.Item
          label={t('Phone')}
          name="phone"
          rules={[{ required: true, message: t('Please input your phone') }]}>
          <PhoneInput placeholder={t('Phone number')} country="no" />
        </Form.Item>
      </Box>
      <Button
        type="primary"
        htmlType="submit"
        block
        mt="auto"
        loading={loading}>
        {t('Save changes')}
      </Button>
    </Form>
  )
}

UserForm.propTypes = {
  initialValues: PropTypes.object.isRequired,
  onFinish: PropTypes.func.isRequired,
  loading: PropTypes.bool,
  setFormLoading: PropTypes.func
}

export default UserForm
