import { PartnerEdit, PartnerShow } from '../routes'
import ROUTE_PATHS from 'domains/allRoutePath'

export default [
  {
    name: 'PartnerEdit',
    path: ROUTE_PATHS.PARTNER_EDIT,
    exact: true,
    component: PartnerEdit
  },
  {
    name: 'PartnerShow',
    path: ROUTE_PATHS.PARTNER_SHOW,
    exact: true,
    component: PartnerShow
  }
]
