import React from 'react'
import PropTypes from 'prop-types'
import {
  Row,
  Col,
  Box,
  Text,
  Avatar,
  Card,
  Button
} from '@qonsoll/react-design'
import { UserOutlined, PhoneOutlined } from '@ant-design/icons'
import { generatePath, useHistory } from 'react-router-dom'
import ROUTE_PATHS from 'domains/allRoutePath'
import { useSessionContext } from 'app/contexts/Session/hooks'

function UserAdvancedView(props) {
  const { id, withCall, onCallClick, phone, name, avatarURL, onClick } = props

  // [ADDITIONAL HOOKS]
  const history = useHistory()
  const { state } = useSessionContext()

  // [CLEAN FUNCTIONS]
  const onAvatarClick = () => {
    history.push(generatePath(ROUTE_PATHS.USER_SHOW, { id }))
  }

  // [COMPUTED_PROPERTIES]
  const isCurrentUser = state.id === id

  return (
    <Card
      mb={3}
      size="small"
      bordered={false}
      shadowless
      style={{ cursor: 'pointer' }}>
      <Row noGutters h="center" v="center">
        <Col cw="auto" mr={3} onClick={onClick}>
          <Box
            height="fit-content"
            width="fit-content"
            border="var(--ql-border-width-2) var(--ql-border-style-default) var(--ql-border-color-default)"
            borderRadius="50%">
            <Avatar
              m="2px"
              icon={<UserOutlined />}
              src={avatarURL}
              onClick={onAvatarClick}
            />
          </Box>
        </Col>
        <Col minWidth={0} onClick={onClick}>
          <Text isEllipsis>{name}</Text>
        </Col>
        {withCall && phone && !isCurrentUser && (
          <Col cw="auto" ml={3}>
            <Button
              href={`tel:${phone}`}
              type="primary"
              icon={
                <PhoneOutlined style={{ fontSize: 24 }} onClick={onCallClick} />
              }
            />
          </Col>
        )}
      </Row>
    </Card>
  )
}

UserAdvancedView.propTypes = {
  id: PropTypes.string,
  name: PropTypes.string,
  withCall: PropTypes.bool,
  onCallClick: PropTypes.func,
  avatarURL: PropTypes.string,
  onClick: PropTypes.func
}

export default UserAdvancedView
