import { useReducer } from 'react'
import PropTypes from 'prop-types'
import CompanyContext from '../../context'
import companyReducer from '../../companyReducer'

const CompanyProvider = (props) => {
  const { company = null, children, ...rest } = props

  // [ADDITIONAL HOOKS]
  const [state, dispatch] = useReducer(companyReducer, company)

  return (
    <CompanyContext.Provider value={{ state, dispatch }} {...rest}>
      {children}
    </CompanyContext.Provider>
  )
}

CompanyProvider.propTypes = {
  children: PropTypes.element.isRequired,
  company: PropTypes.object
}

export default CompanyProvider
