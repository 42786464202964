import { useState, useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import { useCollectionData } from 'react-firebase-hooks/firestore'
import { Space, Empty } from 'antd'
import { Row, Col, PageWrapper, Button, Input } from '@qonsoll/react-design'
import { /*FilterOutlined*/ PlusOutlined } from '@ant-design/icons'
import { getCollectionRef } from '~/services/Firebase/firestore'
import { PageLoading } from '~/app/components'
import { OffersList } from 'domains/Offer/components'
import { COLLECTIONS } from '~/app/constants'
import ROUTE_PATHS from 'domains/allRoutePath'
import { useTranslations } from 'app/contexts/Translation/hooks'
import { useSessionContext } from 'app/contexts/Session/hooks'
import { useResponsiveBreakpoints } from 'app/contexts/UI/hooks'

function OffersAll() {
  // [COMPONENT STATE HOOKS]
  const [offersData, setOffersData] = useState([])

  // [ADDITIONAL_HOOKS]
  const { state } = useSessionContext()
  const { t } = useTranslations()
  const history = useHistory()
  const [offers, loading] = useCollectionData(
    getCollectionRef(COLLECTIONS.OFFERS).where(
      'partnerId',
      '==',
      state.partnerId
    )
  )
  const titleSizes = useResponsiveBreakpoints({
    sm: 4,
    md: 3,
    lg: 3,
    xl: 3,
    xxl: 3
  })

  // [CLEAN_FUNCTIONS]
  const goToCreateOffer = () => {
    history.push(ROUTE_PATHS.OFFER_CREATE)
  }
  // const onFilter = () => {}

  const onOfferSearch = (event) => {
    const filteredOffers = offers?.filter((item) =>
      item?.title.toLowerCase().startsWith(event.target.value.toLowerCase())
    )
    setOffersData(filteredOffers)
  }

  // [USE EFFECTS]
  useEffect(() => {
    setOffersData(offers)
  }, [offers])

  return (
    <PageLoading loading={loading} height="inherit">
      <PageWrapper
        headingProps={{
          title: t('Offers'),
          titleSize: titleSizes,
          marginBottom: '24px'
        }}
        isBottomSticky
        action={
          <Space>
            {/*<Button type="text" icon={<FilterOutlined />} onClick={onFilter} />*/}
            <Button
              type="primary"
              icon={<PlusOutlined />}
              onClick={goToCreateOffer}
            />
          </Space>
        }>
        {offers?.length ? (
          <Row noGutters>
            <Col mb="24px">
              <Input
                placeholder={t('Enter name here')}
                allowClear
                onChange={onOfferSearch}
              />
            </Col>
            <Col cw={12} style={{ minWidth: 0 }}>
              <OffersList data={offersData} />
            </Col>
          </Row>
        ) : (
          <Row height="100%">
            <Col v="center">
              <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
            </Col>
          </Row>
        )}
      </PageWrapper>
    </PageLoading>
  )
}

export default OffersAll
