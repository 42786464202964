import React, { Fragment, useState } from 'react'
import { Form } from 'antd'
import {
  Box,
  Col,
  Row,
  PhoneInput,
  Input,
  Save,
  Title,
  Link,
  TextArea,
  Text
} from '@qonsoll/react-design'
import {
  PlusOutlined,
  MinusCircleOutlined,
  BankOutlined
} from '@ant-design/icons'
import PropTypes from 'prop-types'
import { AvatarUploader } from '~/app/components'
import { useTranslations } from 'app/contexts/Translation/hooks'
import { OfferTypeSelect } from 'domains/OfferType/components'

function PartnerEditForm(props) {
  const {
    form,
    onFinish,
    setFormLoading,
    loading,
    offersTypes,
    initialType,
    logoURL,
    diffImage = [],
    setDiffImage
  } = props

  //[ADDITIONAL HOOKS]
  const { t } = useTranslations()
  const [type, setType] = useState(initialType || {})

  // [CLEAN FUNCTIONS]
  const onChangeSelectType = (_, object) => {
    setType(object.key)
  }
  const onValuesChange = (data) => {
    if ('logoURL' in data) {
      setDiffImage?.([...diffImage, data?.logoURL])
    }
  }
  return (
    <Form
      onFinish={(data) => onFinish(data, type)}
      onValuesChange={onValuesChange}
      form={form}
      layout="vertical"
      requiredMark={false}
      style={{
        display: 'flex',
        flexDirection: 'column'
      }}>
      <Form.Item name="logoURL" style={{ margin: 0 }}>
        <AvatarUploader
          shape="square"
          icon={<BankOutlined />}
          imageUrl={logoURL}
          setLoading={setFormLoading}
        />
      </Form.Item>
      <Form.Item
        label={t('Partner name')}
        name="partnerName"
        rules={[
          { required: true, message: t('Enter partner name, please') },
          {
            max: 150,
            message: t('Name should be less than 150 character')
          }
        ]}>
        <Input autoFocus placeholder={t('Enter partner name')} />
      </Form.Item>
      <Form.Item
        label={t('Type')}
        name="offerTypeId"
        style={{ width: '100%' }}
        rules={[{ required: true, message: t('Enter offer type, please') }]}>
        <OfferTypeSelect
          onChange={onChangeSelectType}
          offerTypes={offersTypes}
          placeholder={t('Type')}
        />
      </Form.Item>
      <Form.Item
        label={t('Description')}
        name="partnerDescription"
        rules={[
          {
            max: 2500,
            message: t('Description should be less than 2500 character')
          }
        ]}>
        <TextArea placeholder={t('Enter description')} rows={3} />
      </Form.Item>
      <Row noGutters mb={24}>
        <Col cw={12} mb={2}>
          <Title level={5}>{t('Contact person')}</Title>
        </Col>
        <Col cw={12}>
          <Text>
            {t(
              'Add information about persons who is responsible to contact with clients'
            )}
          </Text>
        </Col>
      </Row>

      <Form.List name="contactPeople">
        {(fields, { add, remove }) => (
          <Fragment>
            {fields.map(({ key, name, fieldKey, ...restField }) => (
              <Box key={key}>
                <Row noGutters mb={3}>
                  <Col>
                    <Text
                      variant="overline"
                      type="secondary"
                      textTransform="uppercase">
                      {t('Contact person')}
                    </Text>
                  </Col>
                  <Col cw="auto">
                    <MinusCircleOutlined onClick={() => remove(name)} />
                  </Col>
                </Row>
                <Form.Item
                  {...restField}
                  label={t('Email')}
                  name={[name, 'email']}
                  fieldKey={[fieldKey, 'email']}
                  rules={[
                    {
                      required: true,
                      message: t("Enter contact person's e-mail, please")
                    }
                  ]}>
                  <Input placeholder={t('Enter email')} />
                </Form.Item>
                <Row noGutters>
                  <Col cw={12} display="block">
                    <Form.Item
                      {...restField}
                      label={t('First name')}
                      name={[name, 'firstName']}
                      fieldKey={[fieldKey, 'firstName']}
                      rules={[
                        {
                          required: true,
                          message: t(
                            "Enter contact person's first name, please"
                          )
                        }
                      ]}>
                      <Input placeholder={t('Enter first name')} />
                    </Form.Item>
                  </Col>
                  <Col cw={12} display="block">
                    <Form.Item
                      {...restField}
                      label={t('Second name')}
                      name={[name, 'secondName']}
                      fieldKey={[fieldKey, 'secondName']}
                      rules={[
                        {
                          required: true,
                          message: t("Enter contact person's last name, please")
                        }
                      ]}>
                      <Input placeholder={t('Enter last name')} />
                    </Form.Item>
                  </Col>
                </Row>
                <Form.Item
                  {...restField}
                  label={t('Phone')}
                  name={[name, 'phone']}
                  fieldKey={[fieldKey, 'phone']}
                  rules={[
                    {
                      required: true,
                      message: t("Enter contact person's phone, please")
                    }
                  ]}>
                  <PhoneInput placeholder={t('Phone number')} country="no" />
                </Form.Item>
              </Box>
            ))}
            <Link onClick={() => add()} mb={48}>
              <PlusOutlined /> {t('Add new person')}
            </Link>
          </Fragment>
        )}
      </Form.List>
      <Row noGutters mt="auto">
        <Col>
          <Save
            type="primary"
            block
            htmlType="submit"
            text={t('Edit partner')}
            loading={loading}
          />
        </Col>
      </Row>
    </Form>
  )
}

PartnerEditForm.propTypes = {
  form: PropTypes.object.isRequired,
  offersTypes: PropTypes.array.isRequired,
  initialType: PropTypes.object,
  logoURL: PropTypes.string,
  onFinish: PropTypes.object,
  setFormLoading: PropTypes.func,
  loading: PropTypes.bool
}

export default PartnerEditForm
