import React from 'react'
import {
  Container,
  Row,
  Col,
  Box,
  PageWrapper,
  Button,
  Avatar,
  Text,
  Title
} from '@qonsoll/react-design'

import {
  UserOutlined,
  EditOutlined,
  /* DeleteOutlined,*/
  KeyOutlined
} from '@ant-design/icons'
import { PageLoading } from '~/app/components'
import { useHistory, useParams, generatePath } from 'react-router-dom'
import { COLLECTIONS } from '~/app/constants'
import ROUTE_PATHS from 'domains/allRoutePath'
import {
  useBackButton,
  useResponsiveBreakpoints
} from '~/app/contexts/UI/hooks'
import { useSessionContext } from '~/app/contexts/Session/hooks'
import { getCollectionRef } from '~/services/Firebase/firestore'
import { useDocumentData } from 'react-firebase-hooks/firestore'
import { useTranslations } from 'app/contexts/Translation/hooks'

function UserShow() {
  // [ADDITIONAL_HOOKS]
  const backButtonVisibility = useResponsiveBreakpoints({
    sm: false,
    md: true,
    lg: true,
    xl: true,
    xxl: true
  })
  const goBack = useBackButton({ returnCallback: backButtonVisibility })
  const { id } = useParams()
  const history = useHistory()
  const { t } = useTranslations()
  const { state } = useSessionContext()
  const [userData, loading] = useDocumentData(
    getCollectionRef(COLLECTIONS.USERS).doc(id)
  )

  // [COMPUTED PROPERTIES]
  const currentUser = state.id === id

  // [CLEAN_FUNCTIONS]
  const onEditClick = () => {
    history.push(generatePath(ROUTE_PATHS.USER_EDIT, { id }))
  }

  // const onDeleteAccount = () => {}
  const onResetPassword = () => {
    history.push(ROUTE_PATHS.RESET_PASSWORD)
  }

  return (
    <PageLoading loading={loading}>
      <PageWrapper onBack={goBack} divided={false} isBottomSticky>
        <Container
          height="inherit"
          display="flex"
          paddingTop="5%"
          style={{
            flexDirection: 'column',
            justifyContent: 'space-between'
          }}>
          <Row h="center">
            <Col cw={[12, 9, 8, 7]} flexDirection="column">
              <Box
                width="100%"
                margin="0 auto"
                display="flex"
                bg="var(--card-user-show-bg)"
                borderRadius="var(--ql-border-radius-12)"
                pb={2}
                mb={3}>
                <Row width="100%">
                  <Col cw={12} h="center" mb={2}>
                    <Avatar
                      style={{ margin: '0 auto', marginTop: '-60px' }}
                      src={userData?.avatarURL}
                      alt={t('User')}
                      icon={<UserOutlined />}
                      size={128}
                    />
                  </Col>
                  <Col cw={12} h="center" mb={3}>
                    <Title
                      textAlign="center"
                      level={
                        3
                      }>{`${userData?.firstName} ${userData?.secondName}`}</Title>
                  </Col>
                </Row>
              </Box>
              <Row h="center" mb={2}>
                <Col cw={[12, 6, 6, 6]} flexDirection="column" mb={2}>
                  <Text type="secondary" fontSize="var(--ql-font-size-body2)">
                    {t('Email')}
                  </Text>
                  <Text isEllipsis fontWeight="var(--ql-font-weight-medium)">
                    {userData?.email}
                  </Text>
                </Col>
                <Col cw={[12, 6, 6, 6]} flexDirection="column" mb={2}>
                  <Text type="secondary" fontSize="var(--ql-font-size-body2)">
                    {t('Phone')}
                  </Text>
                  <Text fontWeight="var(--ql-font-weight-medium)">
                    {userData?.phone}
                  </Text>
                </Col>
              </Row>
            </Col>
          </Row>
          {currentUser && (
            <Row noGutters>
              <Col cw={12} mb={2}>
                <Button icon={<EditOutlined />} block onClick={onEditClick}>
                  {t('Edit profile')}
                </Button>
              </Col>
              <Col cw={12} mb={2}>
                <Button icon={<KeyOutlined />} block onClick={onResetPassword}>
                  {t('Change password')}
                </Button>
              </Col>
              {/* <Col cw={12}>
                <Remove
                  type="primary"
                  danger
                  text={t('Delete account')}
                  icon={<DeleteOutlined />}
                  block
                  onClick={onDeleteAccount}
                  itemName="account"
                />
              </Col> */}
            </Row>
          )}
        </Container>
      </PageWrapper>
    </PageLoading>
  )
}

export default UserShow
