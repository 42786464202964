import React from 'react'
import PropTypes from 'prop-types'
import { List } from 'antd'
import { UserAdvancedView } from 'domains/User/components'
import { useHistory, generatePath } from 'react-router-dom'
import ROUTE_PATHS from '~/app/domains/allRoutePath'

function UserList(props) {
  const { data } = props

  // [ADDITIONAL_HOOKS]
  const history = useHistory()

  return (
    <List
      dataSource={data}
      renderItem={(item, index) => (
        <UserAdvancedView
          withCall
          name={`${item.firstName} ${item.secondName}`}
          avatarURL={item?.avatarURL}
          id={item.id}
          phone={item?.phone}
          key={index}
          onClick={() =>
            history.push(generatePath(ROUTE_PATHS.USER_SHOW, { id: item.id }))
          }
        />
      )}
      style={{ width: 'inherit' }}
    />
  )
}

UserList.propTypes = {
  data: PropTypes.array.isRequired
}

export default UserList
