import { useHistory, useParams, generatePath } from 'react-router-dom'
import { useDocumentData } from 'react-firebase-hooks/firestore'
import { message, Space } from 'antd'
import { Edit, PageWrapper } from '@qonsoll/react-design'
import { deleteDocument, getCollectionRef } from '~/services/Firebase/firestore'
import {
  useBackButton,
  useResponsiveBreakpoints
} from '~/app/contexts/UI/hooks'
import { PageLoading, Remove } from '~/app/components'
import { COLLECTIONS } from '~/app/constants'
import ROUTE_PATHS from '~/app/domains/allRoutePath'
import { OfferView } from 'domains/Offer/components'
import { useTranslations } from 'app/contexts/Translation/hooks'
import { deleteURL } from '~/services/Firebase/storage'

function OfferShow() {
  // [ADDITIONAL_HOOKS]
  const backButtonVisibility = useResponsiveBreakpoints({
    sm: false,
    md: true,
    lg: true,
    xl: true,
    xxl: true
  })
  const goBack = useBackButton({ returnCallback: backButtonVisibility })
  const { id } = useParams()
  const history = useHistory()
  const { t } = useTranslations()
  const titleSizes = useResponsiveBreakpoints({
    sm: 4,
    md: 3,
    lg: 3,
    xl: 3,
    xxl: 3
  })
  const [offerData, loading] = useDocumentData(
    getCollectionRef(COLLECTIONS.OFFERS).doc(id)
  )
  const images = useResponsiveBreakpoints({
    sm: offerData?.images_500,
    md: offerData?.images_500,
    lg: offerData?.images_1000,
    xl: offerData?.images_1000,
    xxl: offerData?.images_1000
  })
  // [CLEAN FUNCTIONS]
  const onRemove = async () => {
    try {
      //remove images and resize images from storage
      for (const image of offerData?.images)
        await deleteURL(image).catch((error) => {
          console.log(error.message)
        })
      for (const image of offerData?.images_500)
        await deleteURL(image).catch((error) => {
          console.log(error.message)
        })
      for (const image of offerData?.images_1000)
        await deleteURL(image).catch((error) => {
          console.log(error.message)
        })

      //remove offer
      await deleteDocument(COLLECTIONS.OFFERS, id)
      message.success(t('Offer was successfully deleted'))
      history.push(ROUTE_PATHS.OFFERS_ALL)
    } catch (error) {
      console.log(error)
      message.error(t('Error occurred during offer deletion'))
    }
  }

  const onEditClick = (id) =>
    history.push(generatePath(ROUTE_PATHS.OFFER_EDIT, { id }))

  if (!offerData && !loading) {
    history.replace(ROUTE_PATHS[404])
  }
  // const goBack = useBackButton({ returnCallback: backButtonVisibility })
  return (
    <PageLoading loading={loading}>
      <PageWrapper
        headingProps={{
          title: offerData?.title,
          titleSize: titleSizes,
          marginBottom: '24px'
        }}
        onBack={goBack}
        action={[
          <Space>
            <Edit
              type="text"
              onClick={() => onEditClick(id)}
              tooltip={t('Edit')}
              icon
            />
            <Remove
              type="text"
              onSubmit={onRemove}
              icon
              popconfirmPlacement="bottomRight"
            />
          </Space>
        ]}
        // onBack={goBack}
      >
        <OfferView
          {...offerData}
          resizedImages={images}
          onEditClick={onEditClick}
          onRemove={onRemove}
        />
      </PageWrapper>
    </PageLoading>
  )
}

export default OfferShow
