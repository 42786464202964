import React from 'react'
import { Row, Col, Avatar, Title, Text } from '@qonsoll/react-design'
import PropTypes from 'prop-types'
import { BankOutlined } from '@ant-design/icons'
import { UserList } from 'domains/User/components'
import { useTranslations } from 'app/contexts/Translation/hooks'

function PartnerAdvancedView(props) {
  const { logoURL, partnerName, partnerDescription, contactsData } = props

  // [ADDITIONAL HOOKS]
  const { t } = useTranslations()

  return (
    <Row noGutters width="100%">
      <Col cw={12} h="center" mb={2}>
        <Avatar
          shape="square"
          size={64}
          icon={<BankOutlined />}
          src={logoURL}
        />
      </Col>
      <Col cw={12} h="center" mb={3}>
        <Title level={4}>{partnerName}</Title>
      </Col>

      <Col cw={12} mb={24}>
        <Text textAlign="center">{partnerDescription}</Text>
      </Col>
      <Col cw={12} mb={3}>
        <Title level={5}>{t('Contact persons')}</Title>
      </Col>
      <Col cw={12} mb={24}>
        <UserList data={contactsData} />
      </Col>
    </Row>
  )
}

PartnerAdvancedView.propTypes = {
  id: PropTypes.string,
  logoURL: PropTypes.string,
  partnerName: PropTypes.string,
  partnerDescription: PropTypes.string,
  contactsData: PropTypes.array,
  type: PropTypes.string,
  onRemove: PropTypes.func
}

export default PartnerAdvancedView
