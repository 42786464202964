import React from 'react'
import { Button, Result } from 'antd'
import { Row, Col, PageWrapper } from '@qonsoll/react-design'
import { useHistory } from 'react-router-dom'
import { useTranslations } from 'app/contexts/Translation/hooks'

function AccessDenied() {
  // [ADDITIONAL_HOOKS]
  const history = useHistory()
  const { t } = useTranslations()

  // [CLEAN FUNCTIONS]
  const onClickActions = () => {
    history.goBack()
  }
  return (
    <PageWrapper>
      <Row h="center" v="center">
        <Col cw="auto">
          <Result
            status="403"
            title="403"
            subTitle={t('Sorry, you are not authorized to access this page')}
            extra={
              <Button type="primary" onClick={onClickActions}>
                {t('Back')}
              </Button>
            }
          />
        </Col>
      </Row>
    </PageWrapper>
  )
}

export default AccessDenied
