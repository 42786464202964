import { useHistory, generatePath } from 'react-router-dom'
import { Button, List } from 'antd'
import { Row, Col, Container, PageWrapper, Text } from '@qonsoll/react-design'
import { RightOutlined } from '@ant-design/icons'
import auth from '~/services/Firebase/auth'
import ROUTE_PATHS from '~/app/domains/allRoutePath'
import { useSessionContext } from '~/app/contexts/Session/hooks'
import { useTranslations } from 'app/contexts/Translation/hooks'
import { LanguageSelect } from 'domains/Translation/components'
import { useResponsiveBreakpoints } from 'app/contexts/UI/hooks'
import { ThemeSwitch } from 'app/components'

const MENU_ITEMS_MAP = [
  {
    title: 'Dark theme',
    Component: ThemeSwitch
  },
  {
    title: 'Partner profile',
    generatePath: ({ partnerId }) =>
      generatePath(ROUTE_PATHS.PARTNER_SHOW, { id: partnerId })
  },
  {
    title: 'My profile',
    generatePath: ({ userId }) =>
      generatePath(ROUTE_PATHS.USER_SHOW, { id: userId })
  },

  {
    title: 'Cookie Policy',
    generatePath: () => ROUTE_PATHS.GDPR_PAGE
  }
]
function Settings() {
  // [ADDITIONAL_HOOKS]
  const history = useHistory()
  const { state } = useSessionContext()
  const { t } = useTranslations()
  const titleSizes = useResponsiveBreakpoints({
    sm: 4,
    md: 3,
    lg: 3,
    xl: 3,
    xxl: 3
  })

  // [CLEAN FUNCTIONS]
  const onLogoutClick = async () => {
    await auth.signOut()
  }

  return (
    <PageWrapper
      isBottomSticky
      headingProps={{
        title: t('Settings'),
        titleSize: titleSizes,
        marginBottom: '24px'
      }}
      action={<LanguageSelect />}>
      <Container height="inherit" display="grid">
        <Row noGutters>
          <Col cw={12} display="grid">
            <List
              dataSource={MENU_ITEMS_MAP}
              renderItem={(item) => (
                <List.Item
                  style={{ cursor: 'pointer' }}
                  onClick={() =>
                    item?.generatePath &&
                    history.push(
                      item.generatePath({
                        partnerId: state.partnerId,
                        userId: state.id
                      })
                    )
                  }
                  actions={[
                    item?.Component ? (
                      <item.Component />
                    ) : (
                      <RightOutlined
                        style={{
                          fontSize: 'var(--ql-font-size-h3)'
                        }}
                      />
                    )
                  ]}>
                  <Text fontSize={'var(--ql-font-size-body1)'}>
                    {t(item.title)}
                  </Text>
                </List.Item>
              )}
            />
          </Col>
        </Row>
        <Row noGutters mt="auto">
          <Col cw={12}>
            <Button block onClick={onLogoutClick}>
              {t('Logout')}
            </Button>
          </Col>
        </Row>
      </Container>
    </PageWrapper>
  )
}

export default Settings
