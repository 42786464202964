import { useState, useEffect } from 'react'
import { v4 as uuidv4, v5 as uuidv5 } from 'uuid'
import { message, Upload } from 'antd'
import { useTranslations } from 'app/contexts/Translation/hooks'
import { deleteURL, storageRef } from '~/services/Firebase/storage'
import UploadList from './UploadList'

const STORAGE_URL = 'offer-images/'

const Uploader = (props) => {
  const {
    onRemoveForResized,
    children,
    onChange,
    value,
    uploadListProps,
    ...rest
  } = props

  // [ADDITIONAL_HOOKS]
  const { t } = useTranslations()

  // [COMPONENT_STATE_HOOKS]
  const [images, setImages] = useState({})
  const [isInited, setIsInited] = useState(false)

  // [CLEAR_FUNCTIONS]
  const onUpload = (data) => {
    const { file } = data

    // Init uploading (add to list & set loading status)
    const imagePath = STORAGE_URL + uuidv5(file.name, uuidv4())
    const image = {
      name: file.name,
      status: 'uploading',
      percent: 0,
      uid: imagePath
    }

    setImages((images) => ({ ...images, [imagePath]: image }))
    const ref = storageRef({ path: imagePath })

    const uploadTask = ref.put(file, {
      size: file.size,
      fileName: file.name
    })
    uploadTask.on(
      'state_changed',
      (snapshot) => {
        // Calc of upload progress
        const progress = Math.round(
          (snapshot.bytesTransferred / snapshot.totalBytes) * 100
        ).toFixed(0)

        // Update item while it uploading
        const image = {
          name: file.name,
          status: 'uploading',
          percent: progress,
          uid: imagePath
        }
        setImages((images) => ({ ...images, [imagePath]: image }))
      },
      (error) => {
        switch (error.code) {
          case 'storage/unauthorized': {
            message.error(t("You don't have permissions"))
            break
          }
          default: {
            message.error(t('Error ocurred during upload'))
            message.error(error.code)
          }
        }

        const material = {
          name: file.name,
          status: 'error',
          uid: imagePath
        }
        setImages([...images, material])
      },
      async () => {
        // Set result material data to list
        const imageUrl = await uploadTask.snapshot.ref.getDownloadURL()
        const ref = storageRef({ url: imageUrl })
        await ref.updateMetadata({ customMetadata: { fileName: file.name } })
        const image = {
          name: file.name,
          url: imageUrl,
          type: file.type,
          size: file.size,
          uid: imagePath
        }
        setImages((images) => ({ ...images, [imagePath]: image }))
      }
    )
  }

  // [USE_EFFECTS]
  useEffect(
    () => {
      let isComponentMount = true

      isComponentMount && isInited && onChange?.(Object.values(images))

      return () => {
        isComponentMount = false
      }
    }, // eslint-disable-next-line react-hooks/exhaustive-deps
    [images]
  )

  useEffect(
    () => {
      let isComponentMount = true

      if (isComponentMount && Array.isArray(value)) {
        const initValues = {}
        value.forEach(
          (url) =>
            typeof url === 'string' &&
            (initValues[url] = { uid: url, url, name: '', init: true })
        )
        setImages(initValues)
      }
      return () => {
        isComponentMount = false
      }
    }, // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  )
  useEffect(() => {
    let isComponentMount = true
    isComponentMount && setIsInited(true)
    return () => {
      isComponentMount = false
    }
  }, [])

  return (
    <>
      <Upload
        customRequest={onUpload}
        accept="image/*"
        showUploadList={false}
        multiple
        {...rest}>
        {children}
      </Upload>
      <UploadList
        files={Object.values(images)}
        setFiles={setImages}
        onRemove={async (file, index) => {
          await deleteURL(file.url)
          const filtered = { ...images }
          delete filtered[file.uid]
          onRemoveForResized && (await onRemoveForResized(file, index))
          setImages(filtered)
        }}
        {...uploadListProps}
      />
    </>
  )
}

export default Uploader
