import PropTypes from 'prop-types'
import { PageWrapper } from '@qonsoll/react-design'
import { Spinner } from 'app/components'

const PageLoading = (props) => {
  const { loading, loadingText, children, ...rest } = props

  if (loading)
    return (
      <PageWrapper firstLevelHidden alignMiddle {...rest}>
        <Spinner text={loadingText} />
      </PageWrapper>
    )
  return children
}

PageLoading.propTypes = {
  loading: PropTypes.bool,
  children: PropTypes.node,
  loadingText: PropTypes.string
}

export default PageLoading
