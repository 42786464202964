import { CustomCarousel } from 'app/components'
import PropTypes from 'prop-types'
import { Col, Row, Container, Text, Title, Img } from '@qonsoll/react-design'
import {
  EnvironmentOutlined,
  PhoneOutlined,
  MailOutlined
} from '@ant-design/icons'
import { useTranslations } from '~/app/contexts/Translation/hooks'
import { useResponsiveBreakpoints } from 'app/contexts/UI/hooks'

const config = { sm: 240, md: 320, lg: 500, xl: 500, xxl: 500 }
const mobileMap = {
  sm: true,
  md: true,
  lg: false,
  xl: false,
  xxl: false
}

function OfferView(props) {
  const {
    description,
    phone,
    email,
    location,
    currency,
    resizedImages,
    price,
    fixedPrice,
    fixedDiscount,
    fixedDiscountPercentage,
    fixedPercentage
  } = props

  // [ADDITIONAL_HOOKS]
  const { t } = useTranslations()
  const responsive = useResponsiveBreakpoints(config)
  const isMobile = useResponsiveBreakpoints(mobileMap)

  const info = (
    <Row noGutters>
      <Col cw={12} mb={3}>
        <Text whiteSpace="pre-line">{description}</Text>
      </Col>
      {price && (
        <Col
          cw={12}
          mb={3}
          style={{ alignItems: 'baseline' }}
          flexDirection="row">
          <Title level={2} mr={2}>
            {price}
          </Title>
          <Text type="secondary">{currency || t('NOK')}</Text>
        </Col>
      )}
      {fixedPrice && (
        <Col cw={12} mb={3} style={{ alignItems: 'baseline' }}>
          <Text>
            {t('Fixed price:')} {fixedPrice} {currency || t('NOK')}
          </Text>
        </Col>
      )}
      {fixedDiscount && (
        <Col cw={12} mb={3} style={{ alignItems: 'baseline' }}>
          <Text>
            {t('Fixed discount:')} {fixedDiscount} {currency || t('NOK')}
          </Text>
        </Col>
      )}
      {fixedDiscountPercentage && (
        <Col cw={12} mb={3} style={{ alignItems: 'baseline' }}>
          <Text>
            {t('Fixed discount percentage:')} {fixedDiscountPercentage}%
          </Text>
        </Col>
      )}
      {fixedPercentage && (
        <Col cw={12} mb={3} style={{ alignItems: 'baseline' }}>
          <Text>
            {t('Fixed percentage:')} {fixedPercentage}%
          </Text>
        </Col>
      )}
      <Col cw={12} mb={3} flexDirection="row">
        <EnvironmentOutlined
          style={{ fontSize: '24px', width: 'fit-content' }}
        />
        <Text ml={3}>{location}</Text>
      </Col>
      {email && (
        <Col cw={12} mb={3} flexDirection="row">
          <MailOutlined style={{ fontSize: '24px', width: 'fit-content' }} />
          <Text ml={3}>{email}</Text>
        </Col>
      )}
      {phone && (
        <Col cw={12} mb={3} flexDirection="row">
          <PhoneOutlined style={{ fontSize: '24px', width: 'fit-content' }} />
          <Text ml={3}>{phone}</Text>
        </Col>
      )}
    </Row>
  )
  return isMobile ? (
    <Container
      height="inherit"
      display="flex"
      style={{
        flexDirection: 'column',
        justifyContent: 'space-between'
      }}>
      <Row noGutters mb={3} mx={-3} mt={-24}>
        <Col
          cw={12}
          minWidth={0}
          style={{ position: 'relative', height: 'fit-content' }}>
          <CustomCarousel noOuterGutters data={resizedImages}>
            {resizedImages?.map((item, index) => (
              <Img
                key={index}
                src={item}
                alt={item}
                height={responsive}
                objectFit="cover"
              />
            ))}
          </CustomCarousel>
        </Col>
      </Row>
      <>{info}</>
    </Container>
  ) : (
    <Container
      height="inherit"
      display="flex"
      style={{
        flexDirection: 'column',
        justifyContent: 'space-between'
      }}>
      <Row noOuterGutters style={{ alignItem: 'baseline' }}>
        <Col cw={6} style={{ position: 'relative', height: 'fit-content' }}>
          <CustomCarousel data={resizedImages}>
            {resizedImages?.map((item, index) => (
              <Img
                key={index}
                src={item}
                alt={item}
                height={responsive}
                objectFit="cover"
              />
            ))}
          </CustomCarousel>
        </Col>
        <Col cw={6} h={'center'}>
          <Row noGutters>
            <Col cw={12} display={'block'}>
              {info}
            </Col>
          </Row>
        </Col>
      </Row>
    </Container>
  )
}

OfferView.propTypes = {
  description: PropTypes.string.isRequired,
  phone: PropTypes.string.isRequired,
  email: PropTypes.string.isRequired,
  location: PropTypes.string.isRequired,
  category: PropTypes.string.isRequired,
  currency: PropTypes.string.isRequired,
  resizedImages: PropTypes.array.isRequired,
  price: PropTypes.number,
  fixedPrice: PropTypes.number,
  fixedDiscount: PropTypes.number,
  fixedDiscountPercentage: PropTypes.number,
  fixedPercentage: PropTypes.number
}

export default OfferView
