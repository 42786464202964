import { useState, useEffect, useMemo } from 'react'

/**
 * @info useCollectionData (06 Apr 2021) // CREATION DATE
 *
 * @comment useCollectionData - Hook that using to get array of objects from specific collection.
 *
 * @since 06 Apr 2021 ( v.0.0.2 ) // LAST-EDIT DATE
 *
 * @param {CollectionReference}     query               CollectionReference instance that refers to the collection at the specified path.
 * @param {object}									[options]
 * @param {string} 									options.idField			Name of id field of fetched document.
 *
 * @return {[object[], boolean]}
 */

const useCollectionData = (query, options = {}) => {
  // [INTERFACES]
  const { idField } = options

  // [STATE_HOOKS]
  const [loading, setLoading] = useState(true)
  const [data, setData] = useState([])

  // [USE_EFFECTS]
  useEffect(
    () => {
      let isComponentMounted = true
      const unsubscribe =
        isComponentMounted &&
        query.onSnapshot((snapshot) => {
          const snapshotData = snapshot.docs.map((res) =>
            idField ? { ...res.data(), [idField]: res.id } : res.data()
          )
          setData(snapshotData)
          setLoading(false)
        })
      return () => {
        isComponentMounted = false
        unsubscribe?.()
      }
    }, // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  )

  return useMemo(() => [data, loading], [data, loading])
}

export default useCollectionData
