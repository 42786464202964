import { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { Box, Row, Col, Button } from '@qonsoll/react-design'
import { useTranslations } from 'app/contexts/Translation/hooks'

const StyledPopup = styled(Box)`
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100vw;
  padding: 16px 48px;
  background: var(--popup-bg);
`

const GDPR_FLAG = `${process.env.REACT_APP_APPLICATION_NAME}_gdpr`

const GDPRPopup = (props) => {
  const gdpr = JSON.parse(window?.localStorage.getItem(GDPR_FLAG))
  const { whenToShow, children, ...rest } = props

  // [ADDITIONAL_HOOKS]
  const { t } = useTranslations()

  // [COMPONENT_STATE_HOOKS]
  const [show, setShow] = useState(!gdpr)

  // [USE_EFFECTS]
  useEffect(() => {
    let isComponentMount = true
    isComponentMount && setShow(!gdpr)
    return () => {
      isComponentMount = false
    }
  }, [setShow, gdpr])

  return whenToShow
    ? show && (
        <StyledPopup {...rest}>
          <Row v="center" noOuterGutters>
            <Col mb={['16px', 0]}>{children}</Col>
            <Col cw={[12, 'auto']}>
              <Button
                type="primary"
                block
                onClick={() => {
                  localStorage.setItem(GDPR_FLAG, 'true')
                  setShow(false)
                }}>
                {t('Accept')}
              </Button>
            </Col>
          </Row>
        </StyledPopup>
      )
    : null
}

GDPRPopup.propTypes = { whenToShow: PropTypes.bool }

export default GDPRPopup
