import React from 'react'
import { useHistory } from 'react-router-dom'
import { message } from 'antd'
import { PageWrapper } from '@qonsoll/react-design'
import { LoginForm } from 'domains/Session/components'
import auth from 'services/Firebase/auth'
import ROUTE_PATHS from '~/app/domains/allRoutePath'
import { useTranslations } from 'app/contexts/Translation/hooks'
import { useResponsiveBreakpoints } from 'app/contexts/UI/hooks'

function Login() {
  // [ADDITIONAL_HOOKS]
  const history = useHistory()
  const { t } = useTranslations()
  const titleSizes = useResponsiveBreakpoints({
    sm: 3,
    md: 2,
    lg: 2,
    xl: 2,
    xxl: 2
  })

  // [CLEAN FUNCTIONS]
  const onFinish = async (values) => {
    try {
      await auth.signInWithEmailAndPassword(values.email, values.password)
    } catch (err) {
      console.log(err)
      message.error(t('Incorrect email or password'))
    }
  }

  const onForgotPassword = () => {
    history.push(ROUTE_PATHS.FORGOT_PASSWORD)
  }
  // const onLoginWithGoogle = () => {}
  return (
    <PageWrapper
      alignMiddle
      headingProps={{
        title: t('Welcome back'),
        titleSize: titleSizes,
        textAlign: 'center',
        marginBottom: 24
      }}
      contentWidth={['100%', '100%', 400]}>
      <LoginForm onFinish={onFinish} onForgotPassword={onForgotPassword} />
      {/* <Container>
        <Row noGutters>
          <Col cw={12}>
            <Divider>OR</Divider>
          </Col>
          <Col cw={12}>
            <Button
              icon={<GoogleOutlined />}
              size="large"
              block
              onClick={onLoginWithGoogle}>
              Login with google
            </Button>
          </Col>
        </Row>
      </Container> */}
    </PageWrapper>
  )
}

export default Login
