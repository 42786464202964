import { default as AppRoutes } from './App/__constants__/routes'
import { default as PartnerRoutes } from './Partner/__constants__/routes'
import { default as OfferRoutes } from './Offer/__constants__/routes'
import { default as SessionRoutes } from './Session/__constants__/routes'
import { default as UserRoutes } from './User/__constants__/routes'

export default [
  ...AppRoutes,
  ...PartnerRoutes,
  ...OfferRoutes,
  ...SessionRoutes,
  ...UserRoutes
]
