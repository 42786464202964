import PropTypes from 'prop-types'
import { Redirect, Route } from 'react-router-dom'
import ROUTE_PATHS from 'domains/allRoutePath'

const UnauthenticatedRoute = (props) => {
  const { exact, path, component, user } = props

  //needs to add hook that will be get current user
  return !user ? (
    <Route exact={exact} path={path} component={component} />
  ) : (
    <Redirect to={ROUTE_PATHS.OFFERS_ALL} />
  )
}

UnauthenticatedRoute.propTypes = {
  exact: PropTypes.bool.isRequired,
  path: PropTypes.string.isRequired,
  component: PropTypes.node.isRequired
}

export default UnauthenticatedRoute
