import React from 'react'
import ReactDOM from 'react-dom'
import { ThemeProvider } from 'styled-components'
import { BrowserRouter as Router } from 'react-router-dom'
import App from './App'
import { TranslationsProvider } from 'app/contexts/Translation/components'
import { SessionProvider } from 'app/contexts/Session/components'
import { UIProvider } from 'app/contexts/UI/components'
import '@qonsoll/react-design/dist/styles/styles.css'
import './styles/styles.css'
import theme from './styles/theme'

ReactDOM.render(
  <Router>
    <ThemeProvider theme={theme}>
      <TranslationsProvider>
        <SessionProvider>
          <UIProvider>
            <App />
          </UIProvider>
        </SessionProvider>
      </TranslationsProvider>
    </ThemeProvider>
  </Router>,
  document.getElementById('root')
)
