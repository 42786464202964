import PropTypes from 'prop-types'
import { useHistory, generatePath } from 'react-router-dom'
import {
  Row,
  Col,
  Box,
  AppHeaderWrapper,
  Img,
  Avatar,
  Button
} from '@qonsoll/react-design'
import { ArrowLeftOutlined, UserOutlined } from '@ant-design/icons'
import ROUTE_PATHS from 'domains/allRoutePath'
import { useTranslations } from 'app/contexts/Translation/hooks'
import { useUI } from 'app/contexts/UI/hooks'

const THEME_LOGO = {
  dark: 'light',
  light: 'dark'
}

const AppHeader = (props) => {
  const { session } = props

  // [ADDITIONAL_HOOKS]
  const UI = useUI()
  const history = useHistory()
  const { t } = useTranslations()

  // [CLEAN_FUNCTIONS]
  const goToHome = () => {
    history.push(ROUTE_PATHS.OFFERS_ALL)
  }
  const goToProfile = () => {
    history.push(generatePath(ROUTE_PATHS.USER_SHOW, { id: session.id }))
  }

  return (
    <AppHeaderWrapper
      mt={UI.state?.safeAreaInsets?.top}
      py={1}
      px={3}
      minHeight={56}
      maxHeight={56}>
      {session ? (
        <Row noGutters v="center" minHeight="inherit">
          <Col>
            {UI.state.onBack && (
              <Button
                type="text"
                icon={<ArrowLeftOutlined />}
                onClick={UI.state.onBack}
              />
            )}
          </Col>
          <Col cw="auto" style={{ cursor: 'pointer' }}>
            <Img
              height="32px"
              src={`/logo/logo-symbol-${
                THEME_LOGO[session?.themeMode || 'light']
              }.svg`}
              alt={t('Buy together logo')}
              onClick={goToHome}
            />
          </Col>
          <Col h="right" style={{ cursor: 'pointer' }}>
            {/* <Badge size="small" count={63} offset={[-12, 16]}>
              <Button type="text" icon={<BellOutlined />} />
            </Badge> */}
            <Box
              mx={2}
              height="fit-content"
              width="fit-content"
              border="var(--ql-border-width-2) var(--ql-border-style-default) var(--ql-border-color-default)"
              borderRadius="50%"
              onClick={goToProfile}>
              <Avatar
                width={30}
                height={30}
                display="flex"
                alignItems="center"
                justifyContent="center"
                fontSize="16px !important"
                m="2px"
                icon={<UserOutlined />}
                src={session?.avatarURL}
              />
            </Box>
          </Col>
        </Row>
      ) : (
        <Row minHeight="inherit" v="center" h="center">
          <Col cw="auto">
            <Img
              height="32px"
              src={`/logo/logo-symbol-${
                THEME_LOGO[localStorage.getItem('theme') || 'light']
              }.svg`}
              alt={t('Buy together logo')}
            />
          </Col>
        </Row>
      )}
    </AppHeaderWrapper>
  )
}
AppHeader.propTypes = {
  session: PropTypes.oneOfType([PropTypes.bool, PropTypes.object])
}
export default AppHeader
